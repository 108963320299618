var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    { attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title } },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("EmcTableItems", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading,
          "show-delete": false
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.user",
            fn: function(ref) {
              var item = ref.item
              return [
                item.user
                  ? _c("span", [_vm._v(_vm._s(item.user.name))])
                  : _vm.getUser(item)
                  ? _c("span", [_vm._v(_vm._s(_vm.getUser(item).name))])
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("emc-modal-form", {
        attrs: {
          title: _vm.titleForm,
          icon: _vm.$route.meta.icon,
          show: _vm.showForm
        },
        on: {
          "update:show": function($event) {
            _vm.showForm = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "form",
            fn: function() {
              return [
                _vm.model
                  ? _c(
                      "v-card",
                      { staticClass: "mx-auto" },
                      [
                        _c(
                          "v-app-bar",
                          { attrs: { color: "white" } },
                          [
                            _vm.getUser()
                              ? _c("EmcBaseAvatar", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    size: 36,
                                    src: _vm.getUser().url_avatar
                                      ? _vm.getUser().url_avatar
                                      : "",
                                    text: _vm.getUser().initials
                                      ? _vm.getUser().initials
                                      : "SU"
                                  }
                                })
                              : _vm._e(),
                            _c("v-toolbar-title", [
                              _vm.getUser()
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.getUser().name) + " ")
                                  ])
                                : _c("span", [
                                    _vm._v("Sem registro de usuário")
                                  ]),
                              _vm.model.ip
                                ? _c("small", [
                                    _c("br"),
                                    _vm._v("IP: " + _vm._s(_vm.model.ip))
                                  ])
                                : _vm._e()
                            ]),
                            _c("v-spacer"),
                            _c("v-toolbar-title", [
                              _vm._v(
                                " [" +
                                  _vm._s(_vm.model.method) +
                                  "] - " +
                                  _vm._s(_vm.model.path)
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-row",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _vm.model.properties
                                      ? _c("vue-json-pretty", {
                                          attrs: {
                                            path: "res",
                                            data: _vm.model.properties.params
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "small",
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-clock-outline")
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "data " +
                                      _vm._s(
                                        _vm._f("dateTime")(_vm.model.created_at)
                                      )
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }